import React from 'react';
import { navigate } from 'gatsby';
import { useCustomerContext, isExpired } from '../contexts/CustomerContext';
import OrderScheduler from '../components/ShippingScheduler/OrderScheduler';
import Seo from '../components/Seo/seo';

export default function SchedulePage(props) {
    const { customer } = useCustomerContext();

    if (typeof window==='undefined') return null;

    const urlParams = new URLSearchParams(props.location.search);
    const orderId = urlParams.get('order') ?? null;
    const referrer = urlParams.get('referrer') ?? null;

    if (!orderId) navigate('/404');

    if (!customer.token.length || isExpired(customer)) navigate('/reauthenticate');

    return (
        <>
            <OrderScheduler referrer={referrer} orderId={orderId} />
        </>
    );
}

export const Head = () => {
    return <Seo title={"Order schedule"}/>;
}
