import React from 'react';
import { Link } from 'gatsby';
import StandardTop from '../../Product/Page/StandardTop';

export default function Certification(props) {
    console.log('props', props);
    const displayProduct = {
        name: 'Certification',
        short_description: '',
        ont_product_overview: '',
        ont_image_url_1: 'https://images.ctfassets.net/obrbum38fjyj/3BCV0NmFsBTaO9GAZjIylV/3899d826e083f8da6257af5f1bbc9cbf/Lab_Certification_LAB-FEE.png',
        ont_category_type: 'CERTIFICATION'
    }

    return (
        <>
            <StandardTop category={props.category} product={displayProduct}>
                <p style={{"marginTop": "10%"}}>Certification is evolving in response to feedback from our Service Provider partners. If you are currently a Certified Service Provider, we will honour your certification to the end of your agreement. Certification will evolve to a new application-focused training menu that supports Service Providers to expand their Oxford Nanopore service. The training packages are in development and will be available in the first half of 2025. In the meantime, you can still access <Link to="/training.html">Advanced Training for GridION and PromethION devices.</Link></p>
            </StandardTop>
        </>
    );
}
