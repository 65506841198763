import React, { useState } from 'react';
import TopActions from '../../TopActions';
import { TransactionalContent, isChinaStore } from '../../../hooks/siteData';
import ProgressBar from '../../Product/Configurator/ProgressBar';
import ProductHeader from './productHeader';
import PromethionFilter from './promethionFilter';
import ProductCard from './productCard';
import * as styles from './style.module.css';
import Header from '../../Header';
import { NanoGrid } from '@nanoporetech-digital/components-react';
import { useTranslation } from 'react-i18next';

export default function Configurator(props) {
    const [promethionType, setPromethionType] = useState('project-pack');
    const { t } = useTranslation();

    const hasFilter = (props.products.length > 3 && props.category.url_key==='promethion');

    return (
        <>
         <TopActions black="black"/>
            <div className={styles.contentWrapper}>
                <Header category={props.category} title={t("Configure your package")} />
                <TransactionalContent>
                    <div style={{marginBottom: "3rem"}} />
                    <ProgressBar steps={['Select package', 'Choose package to view available options']}/>
                    {/* <ProgressBar steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} /> */}
                </TransactionalContent>
                <NanoGrid l-cols="10"
                    m-cols="10"
                    s-cols="10"
                    xl-cols="10"
                    xxl-cols="10" className={styles.sectionProductHeader}>
                <ProductHeader category={props.category} />
                {hasFilter && <PromethionFilter category={props.category} promethionType={promethionType} setPromethionType={setPromethionType} />}
                <ProductCards products={props.products} category={props.category} filter={hasFilter ? promethionType : false} />
                </NanoGrid>

            </div>
        </>
    );
}

function ProductCards({products, filter, category}) {
    const filteredProducts = function() {
        if (!filter) return products;

        const filtered = [];
        products.forEach(product => {
            if (product.url_key.includes(filter)) filtered.push(product);
        });

        return filtered;
    }();

    const contactLink = isChinaStore()
        ? "//nanoporetech.net/contact"
        : `mailto:store@nanoporetech.com?subject=${category.name} enquiry`;

    if (!products.length) {
        return <p className={styles.contact}>Please <a onClick={() => window.location.href=contactLink}>contact us</a> for further information about these products.</p>;
    }

    return (
        <div grid-states="xxl-col-span-10 s-col-span-10">
            <div className={styles.cards}>
                {filteredProducts.map(product => <ProductCard product={product} category={category} key={product.sku} />)}
            </div>
        </div>
    );
}
