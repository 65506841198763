import React from 'react';
import Header from '../../Header';
import Configurator from './Configurator';
import LicenseWarranty from './LicenseWarranty';
import RapidStartInfo from './RapidStartInfo';
import { contentWrapper } from './style.module.css'

export default function Minion({category}) {
    return (
        <>
            <div className={contentWrapper}>
                <Header category={category} title={category.name} />
                <Configurator category={category} />
                {category.url_key==='minion' && <LicenseWarranty />}
                <RapidStartInfo />
            </div>
        </>
    );
}
