import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMinionContext } from '../Configurator/useMinionContext';
import SurchargeCheckbox from '../SurchargeCheckbox';

export default function Enhance({category}) {
    const minionContext = useMinionContext();
    const { t } = useTranslation();
    const isPremiumBundleAvailable = category.products.items.length > 1;

    return (
        <div>
            <h3>{t("Enhance your Starter Pack")}</h3>
            {isPremiumBundleAvailable && <p>
                <SurchargeCheckbox name="enhancedPack" {...minionContext}>
                    {t("Add 2 extra flow cells at a discounted price")}
                </SurchargeCheckbox>
            </p>}
            <p>
                <SurchargeCheckbox name="rapidStart" {...minionContext}>
                    {t("2 days Rapid Start online training, introducing nanopore sequencing and analysis workflows")}
                </SurchargeCheckbox>
            </p>
        </div>
    );
}

