import React, {useState} from 'react';
import CountrySelect from "../components/CountrySelect";
import Seo from "../components/Seo/seo";

export default function CountrySelectPage(props) {
    return <CountrySelect />
}

export const Head = () => {
    return <Seo title={"Choose country"}/>;
}
