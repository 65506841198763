import React from 'react';
import Cart from '../components/Cart';
import Seo from '../components/Seo/seo';

export default function CartPage(props) {

    if (typeof window==='undefined') return null;

    return (
        <>
            <Cart {...props} />
        </>
    );
}

export const Head = () => (
    <Seo title={"Shopping basket"}/>
)
