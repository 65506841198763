import React from 'react';
import QuickOrder from '../components/QuickOrder';
import Seo from '../components/Seo/seo';

export default function OrderPage() {
    if (typeof window==='undefined') return null;

    return (
        <>
            <QuickOrder />
        </>
    );
}

export const Head = () => (
    <Seo title={"Quick Order"} />
)
