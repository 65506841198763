import React from 'react';
import { navigate } from 'gatsby';
import {gql, useLazyQuery} from "@apollo/client";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import getProductByUrlKey from "../../../utils/product";
import Product from "../../Product";
import {productFields} from "../../../fragments/live/product";
import LiveSeo from "../../Seo/liveSeo";

export default function LiveProduct({urlKey}) {
    if (typeof window === 'undefined') return null;

    const [makeProductsQuery, productsResult] = useLazyQuery(productsQuery, {variables: { urlKey: urlKey }, fetchPolicy: 'no-cache'});
    const [makeSiblingsQuery, siblingsResult] = useLazyQuery(siblingsQuery, {fetchPolicy: 'network-only'});

    if (!productsResult.called) {
        makeProductsQuery();
    }

    let isSiblingsQueryNeeded = false;
    if (productsResult.called && productsResult.data && !siblingsResult.called) {
        const product = getProductByUrlKey(urlKey, productsResult.data.productDetail.items);
        if (!product) navigate('/404');
        const siblingKeys = product.category_siblings.map(sibling => sibling.url_key);
        isSiblingsQueryNeeded = (siblingKeys.length > 0);
        if (isSiblingsQueryNeeded) makeSiblingsQuery({variables: {categorySiblingUrlKeys: siblingKeys}});
    }

    if (productsResult.loading || siblingsResult.loading) {
        return (
            <center><br/><FontAwesomeIcon icon={faSpinner} size="lg" spin /></center>
        );
    }

    if (productsResult.data && (siblingsResult.called || !isSiblingsQueryNeeded)) {
        const product = getProductByUrlKey(urlKey, productsResult.data.productDetail.items);
        const siblingProducts = (siblingsResult.data)
            ? siblingsResult.data.siblingProductsDetail.items
            : [];

        return (
            <>
                <LiveSeo title={product.name} />
                <Product
                    product={product}
                    urlKey={urlKey}
                    siblingProducts={siblingProducts}
                />
            </>
        );
    }

    return null;
}

const productsQuery = gql`
    query ($urlKey: String) {
        productDetail: products(filter: { url_key: { eq: $urlKey } }) {
            items {
                ...productFields
                ...bundleProductFields
                ...configurableProductFields
                ...groupedProductFields
                ...relatedProductFields
                ...virtualProductFields
            }
        }
    }

    ${productFields}
`;

const siblingsQuery = gql`
    query ($categorySiblingUrlKeys: [String]) {
        siblingProductsDetail: products(filter: { url_key: { in: $categorySiblingUrlKeys } }) {
            items {
                ...productFields
                ...bundleProductFields
                ...configurableProductFields
                ...groupedProductFields
                ...relatedProductFields
                ...virtualProductFields
            }
        }
    }

    ${productFields}
`;
