import React from 'react';
import Checkout from '../components/Checkout';
import Seo from '../components/Seo/seo';

export default function CheckoutPage(props) {
    if (typeof window==='undefined') return null;

    return (
        <>
            <Checkout {...props} />
        </>
    );
}

export const Head = () => (
    <Seo title={"Checkout"} />
)
