import React from 'react';
import { navigate } from 'gatsby';
import { useQuery, gql } from "@apollo/client";
import { productFields } from '../fragments/live/product';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import Workshop from '../components/Product/Virtual/Workshop';
import Seo from '../components/Seo/seo';

export default function WorkshopPage(props) {
    if (typeof window==='undefined') return null;

    const {error, loading, data} = useQuery(productsQuery, {
        fetchPolicy: 'network-only',
        variables: { sku: "SUPP-003" },
    });

    const urlParams = new URLSearchParams(props.location.search);
    const token = urlParams.get('token') ?? null;

    if (!token || error) navigate('/404');

    if (data?.productDetail?.items?.length) {
        return (
            <>
                <Workshop product={data.productDetail.items[0]} token={token} />
            </>
        );
    }

    return <center><br/><FontAwesomeIcon icon={faSpinner} size="lg" spin /></center>;
}

export const Head = () => {
    return <Seo title={"Workshops"}/>;
}

const productsQuery = gql`
    query ($sku: String) {
        productDetail: products(filter: { sku: { eq: $sku } }) {
            items {
                ...productFields
                ...bundleProductFields
                ...configurableProductFields
                ...groupedProductFields
                ...relatedProductFields
                ...virtualProductFields
            }
        }
    }

    ${productFields}
`;
