import React from 'react';
import QuotationCreate from '../components/Quotation/Create';
import Seo from '../components/Seo/seo';

export default function CreateQuotePage(props) {
    if (typeof window==='undefined') return null;

    return (
        <>
            <QuotationCreate {...props} />
        </>
    );
}

export const Head = () => (
    <Seo title={"Create a quote"} />
)
