import React from 'react';
import { graphql, useStaticQuery } from "gatsby";
import Home from "../components/Home";
import Seo from '../components/Seo/seo';

export default function Index() {
    const { defaultCategoryChildren, filterMeta } = getData();

    return (
      <>
          <Home
            defaultCategoryChildren={defaultCategoryChildren}
            filterMeta={filterMeta}
          />
      </>
    );
}

export const Head = () => (
    <Seo title={"Store home"} />
)

function getData() {
    const data =  useStaticQuery(graphql`
fragment category on Magento_CategoryInterface {
  id
  name
  url_key
  is_anchor
  position
  level
  include_in_menu
  ont_category_type
  ont_category_phase
  ont_phase_info {
    id
    level
    name
    help
  }
}

{
  magento {
    customAttributeMetadata(attributes: [{attribute_code: "ont_multiplexing", entity_type: "4"}, {attribute_code: "ont_approach", entity_type: "4"}, {attribute_code: "ont_sample_type", entity_type: "4"}]) {
      items {
        attribute_code
        attribute_type
        attribute_options {
          label
          value
        }
      }
    }
    categoryList(filters: {name: {match: "Default Category"}}) {
      children {
        ...category
        products {
          items {
            id
            name
            url_key
            ont_image_url_1
            price_range {
              minimum_price {
                final_price {
                  currency
                  value
                }
              }
            }
          }
        }
        children {
          ...category
          children {
            ...category
          }
        }
      }
    }
  }
}
    `);

    return {
        defaultCategoryChildren: data.magento.categoryList[0].children,
        filterMeta: data.magento.customAttributeMetadata,
    };
}
