import React from 'react';
import { NanoSlides } from '@nanoporetech-digital/components-react';
import Slide from '../Slide';
import { slides } from './style.module.css';

export default function ImageBox({category}) {
    const images = getAllImagesFromCategory(category);

    return (
        <div className={slides}>
            <NanoSlides navbtns={images.length > 1} pager={images.length > 1}>
                {images.map((url, i) => <Slide url={url} key={i} />)}
            </NanoSlides>
        </div>
    );
}


function getAllImagesFromCategory(category) {
    const images = new Set();
    if (category.ont_image_url_1?.length > 0) images.add(category.ont_image_url_1);
    category.products.items.forEach(product => {
        if (product.ont_image_url_1?.length > 0) images.add(product.ont_image_url_1);
    });
    return Array.from(images);
}
